'use client'

import { forwardRef } from 'react';

import IconInput, { IconInputProps } from './IconInput';

export default forwardRef<HTMLInputElement, Omit<IconInputProps, 'type' | 'icon'>>(
  function EmailInput(props, ref) {

    return (
      <IconInput type="email" {...props} ref={ref} icon='envelope' />
    );
  },
);

