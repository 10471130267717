import cn from 'classnames';
import { TextareaHTMLAttributes, forwardRef } from 'react';

interface TextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: boolean;
}

export default forwardRef<HTMLTextAreaElement, TextAreaProps>(function TextArea(
  { error, className, ...props }: TextAreaProps,
  ref,
) {
  return (
    <textarea
      className={cn(
        ' w-full rounded-md border-2 border-gray-650 bg-white px-4 py-2 text-black outline-none placeholder:text-sm placeholder:text-gray-500 focus:border-premium-500',
        {
          'border-red-500': error,
        },
        className,
      )}
      {...props}
      ref={ref}
    />
  );
});
