'use client';
import cn from 'classnames';
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';

import { Icon, IconType } from '@components/icons';

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
}

const InnerInput = forwardRef<HTMLInputElement, InputProps>(function IconInput(
  { error, className, ...props },
  ref,
) {
  return (
    <input
      {...props}
      ref={ref}
      className={cn(
        'w-full border-2 rounded border-gray-650 bg-white pr-8 pl-10 py-2 text-black outline-none placeholder:text-sm placeholder:text-gray-700 focus:border-blue',
        { 'border-red-500': error },
        className,
      )}
    />
  );
});

export type IconInputProps = InputProps & { containerClass?: string, children?: ReactNode, icon: IconType };

function IconInput({ icon, containerClass, children, ...props }: IconInputProps, ref) {
  return <div className={cn("relative text-black", containerClass)}>
    <InnerInput {...props} ref={ref} />
    <span className="absolute left-0 top-[50%] pl-4 translate-y-[-50%]">
      <Icon icon={icon} size={14} />
    </span>
    {children}
  </div>
}

export default forwardRef<HTMLInputElement, IconInputProps>(IconInput)