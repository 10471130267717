'use client';

import { forwardRef, useState } from 'react';
import { FiEyeOff, FiEye } from 'react-icons/fi';

import IconInput from './IconInput';
import { InputProps } from './Input';

export default forwardRef<HTMLInputElement, Omit<InputProps, 'type'>>(
  function PasswordInput(props, ref) {
    const [showPassword, setShowPassword] = useState(false);

    return (
      <IconInput icon={'padlock'} {...props} type={showPassword ? 'text' : 'password'} ref={ref}>
        <button
          type="button"
          onClick={() => setShowPassword((prevVal) => !prevVal)}
          className="absolute right-0 top-0 h-full px-4 py-2"
        >
          {showPassword ? <FiEyeOff /> : <FiEye />}
        </button>
      </IconInput>
    );
  },
);
