import cn from 'classnames';
import { InputHTMLAttributes, ReactNode, forwardRef } from 'react';

interface RadioProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  wrapperClassName?: string;
  label: ReactNode;
  error?: boolean;
  tooltip?: string;
  required?: boolean;
}

function Radio(
  { label, className, error, required, ...props }: RadioProps,
  ref,
) {
  const content = (
    <label className={cn('flex items-top text-xs gap-2', { 'text-red-500': error })}>
      <input
        {...props}
        className={cn('radio mr-1 shrink-0', className)}
        type="radio"
        ref={ref}
      />
      <span className={cn('self-center', { 'ml-1': error })}>
        {label}
        {required && <span className="text-red-500">*</span>}
      </span>
    </label>
  );

  return content;
}

export default forwardRef<HTMLInputElement, RadioProps>(Radio)
