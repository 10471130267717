import cn from 'classnames';
import { ButtonHTMLAttributes } from 'react';
import { CgSpinner } from 'react-icons/cg';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * If `true`, the button will show a spinner
   */
  loading?: boolean;
}

export default function Button({
  className,
  children,
  loading,
  ...props
}: ButtonProps) {
  return (
    <button
      {...props}
      className={cn(
        'flex w-full items-center justify-center block max-w-96 h-[42px] rounded-3xl border bg-grenade px-4 py-2 text-sm text-center transition-colors text-white hover:text-white font-bold hover:bg-blue disabled:cursor-not-allowed disabled:opacity-60 disabled:hover:bg-blue dark:bg-white dark:text-premium-900 dark:hover:bg-premium-900 dark:hover:text-white dark:disabled:hover:bg-white dark:disabled:hover:text-premium-900',
        className,
      )}
    >
      {loading && <CgSpinner className="mr-1 animate-spin" />}
      {children}
    </button>
  );
}
