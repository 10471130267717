'use client';
import cn from 'classnames';
import { useTranslations } from 'next-intl';
import { InputHTMLAttributes, ReactNode, forwardRef, useState } from 'react';

interface CheckboxProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'type'> {
  wrapperClassName?: string;
  label: ReactNode;
  error?: boolean;
  tooltip?: string;
  required?: boolean;
}

export const ExpandableText = ({ text, required, expandedText, error }) => {
  const t = useTranslations('main');
  const [expanded, setExpanded] = useState(false);
  return (
    <span className={cn('self-center text-xs leading-4 block', { 'ml-1': error })}>

      {expanded ? expandedText : text}
      {required && <span className="text-red-500">*</span>}
      {!!expandedText && <>&nbsp;<button className='font-bold opacity-[0.5] hover:opacity-[1]' type="button" onClick={() => setExpanded(!expanded)}>

        {!expanded && (t('navigation.readMore'))}
        {expanded && (t('navigation.readLess'))}
      </button></>}
    </span>
  );
};


export default forwardRef<HTMLInputElement, CheckboxProps>(function Checkbox(
  { label, className, error, tooltip, required, wrapperClassName, ...props },
  ref,
) {
  const content = (
    <label className={cn('flex items-top text-xs gap-2', wrapperClassName, { 'text-red-500': error })}>
      <input
        {...props}
        className={cn('checkbox mr-1 shrink-0', className)}
        type="checkbox"
        ref={ref}
      />
      <ExpandableText text={label} required={required} expandedText={tooltip} error={error} />
    </label>
  );

  return content;
});
