'use client';

import cn from 'classnames';
import { SelectHTMLAttributes, OptionHTMLAttributes, useState, forwardRef, ChangeEvent } from 'react';

interface OptionProps
  extends Omit<OptionHTMLAttributes<HTMLOptionElement>, 'type'> {
  label: string;
  value: string;
}

interface SelectProps
  extends Omit<SelectHTMLAttributes<HTMLSelectElement>, 'type'> {
  wrapperClassName?: string;
  options?: OptionProps[];
  error?: boolean;
  placeholder?: string;
  required?: boolean;
  defaultSelected?: string;
}

function Select(
  { className, options, error, placeholder, required, defaultSelected, ...props }: SelectProps,
  ref,
) {
  const [isSelected, setIsSelected] = useState<boolean>((!defaultSelected == false));
  const onSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    setIsSelected(!!e.target.value);
  };
  const textColor = isSelected ? 'text-black' : 'text-gray-500';
  const content = (
    <select
      {...props}
      ref={ref}
      required={required}
      multiple={false}
      className={cn(
        textColor,
        'w-full rounded-md border-2 border-gray-650 bg-white px-4 py-2 outline-none focus:border-premium-500',
        { 'border-red-500': error },
        className,
      )}
      onChange={onSelect}
    >
      {(!defaultSelected) && (
        <option value={placeholder} disabled>{placeholder}</option>
      )}
      {options && options.map((option) => (
        <option
          {...option}
          key={option.value}
          value={option.value}
          label={option.label}
        ></option>
      ))}
    </select>
  );

  return content;
}

export default forwardRef<HTMLSelectElement, SelectProps>(Select)
